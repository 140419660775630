<template>
    <div class="editaskBox">
        <van-nav-bar :title="title" left-text="返回" @click-left="back" fixed left-arrow />
        <div class="content">
            <van-form>
                <div v-if="showOrNot">
                    <van-cell title="所属项目" :value="select_project" @click="select_project_show = true,select_project_show_searchText = '',onSearchProject()"></van-cell>
                    <van-popup v-model="select_project_show" position="bottom">
                        <van-search v-model.trim="select_project_show_searchText" placeholder="输入项目名称搜索" @input="onSearchProject"></van-search>
                        <div style="minHeight:300px;">
                            <van-radio-group v-model="taskform.projectId">
                                <div class="ewProjectlist">近期选择项目</div>
                                <van-radio v-for="(uitem, index) in integrationProjectList" :key="index" :name="uitem" style="padding:10px">
                                    <span>{{uitem.projectName}}</span>
                                </van-radio>
                            </van-radio-group>
                            <van-radio-group v-model="taskform.projectId">
                                <div class="ewProjectlist">全部项目</div>
                                <van-radio v-for="uitem in select_project_array" :key="uitem.id" :name="uitem" style="padding:10px">
                                    <span>{{uitem.projectName}}</span>
                                </van-radio>
                            </van-radio-group>
                            <van-button style="width:100%;position: -webkit-sticky;position: sticky;bottom: 0;" @click="selectProject()">确定</van-button>
                        </div>
                </van-popup>
                    <van-cell title="所属任务分组" :value="select_grouping" @click="select_grouping_show = true"></van-cell>
                    <van-popup v-model="select_grouping_show" position="bottom">
                        <van-picker
                        show-toolbar
                        :columns="select_grouping_array"
                        @confirm="selectGrouping"
                        @cancel="select_grouping_show = false;$forceUpdate();">
                            <template #option="item">
                                {{item.name}}
                            </template>
                        </van-picker>
                    </van-popup>
                    <van-cell title="所属任务列表" :value="select_list" @click="select_list_show = true"></van-cell>
                    <van-popup v-model="select_list_show" position="bottom">
                        <van-picker
                        show-toolbar
                        :columns="select_list_array"
                        @confirm="selectList"
                        @cancel="select_list_show = false;$forceUpdate();">
                            <template #option="item">
                                {{item.stagesName}}
                            </template>
                        </van-picker>
                    </van-popup>
                </div>
                <!-- 类型 -->
                <van-field v-model="taskform.taskType" label="类型" @click="taskType.show = true" readonly clickable>
                    <template #input><span>{{taskType.list[taskform.taskType]}}</span></template>
                </van-field>
                <van-popup v-model="taskType.show" position="bottom" v-if="canEdit">
                    <van-picker
                    show-toolbar
                    :columns="taskType.list"
                    @confirm="tasktypeChange"
                    @cancel="taskType.show = false;$forceUpdate();"/>
                </van-popup>
                <!-- 任务内容 -->
                <van-field v-model="taskform.name" label="任务内容" placeholder="请输入任务内容" :rules="[{ required: true, message: '请输入任务内容' }]" type="textarea" :disabled="!canEdit" :maxlength="40" show-word-limit></van-field>
                <!-- 开始时间 -->
                <van-field v-if="taskform.type != 1" v-model="taskform.startDate" label="开始时间" placeholder="请选择开始时间" @click="startDateShow = true" readonly clickable></van-field>
                <van-popup v-model="startDateShow" position="bottom" v-if="canEdit">
                    <van-datetime-picker
                    type="date"
                    title="选择开始时间"
                    v-model="currentDate1"
                    @confirm="startDateChange"
                    @cancel="startDateShow = false;$forceUpdate();"
                    :min-date="minDate"
                    :max-date="maxDate"/>
                </van-popup>
                <!-- 截止时间 -->
                <van-field v-model="taskform.endDate" label="截止时间" placeholder="请选择截止时间" @click="endDateShow = true" readonly clickable></van-field>
                <van-popup v-model="endDateShow" position="bottom" v-if="canEdit">
                    <van-datetime-picker
                    type="date"
                    title="选择截止时间"
                    v-model="currentDate2"
                    @confirm="endDateChange"
                    @cancel="endDateShow = false;$forceUpdate();"
                    :min-date="minDate"
                    :max-date="maxDate"/>
                </van-popup>
                <!-- 完成时间 -->
                <van-field v-if="taskform.type == 1" v-model="taskform.finishDate" label="完成时间" placeholder="请选择完成时间" @click="finishDateShow = true" readonly clickable></van-field>
                <van-popup v-model="finishDateShow" position="bottom" v-if="canEdit">
                    <van-datetime-picker
                    type="date"
                    title="选择完成时间"
                    v-model="currentDate3"
                    @confirm="finishDateChange"
                    @cancel="finishDateShow = false;$forceUpdate();"
                    :min-date="minDate"
                    :max-date="maxDate"/>
                </van-popup>
                <!-- 执行人 -->
                <div style="border: 0.5px solid #87c3ff;margin:0.2rem;position:relative" v-for="item,index in taskform.executorList" :key="index">
                    <van-field v-model="item.executorName" :label="'执行人' + (index + 1)" placeholder="请选择执行人" @click="executorChange(item,index)" readonly clickable>
                        <template #input>
                            <span v-if="!item.executorName"></span>
                            <span v-else-if="user.userNameNeedTranslate != 1">{{item.executorName}}</span>
                            <span v-else><TranslationOpenDataText type='userName' :openid='item.executorName'></TranslationOpenDataText></span>
                        </template>
                    </van-field>
                    
                    <van-field label="计划工时">
                        <template #input>
                            <van-stepper v-model="item.planHours" :disabled="!canEdit"/><span>{{'\u3000h'}}</span>
                        </template>
                    </van-field>
                    <van-icon v-if="index != 0 && canEdit" class="delete_executor" name="delete-o" @click.stop="deleteExecutor(index)" />

                    <van-cell-group v-if="user.companyId == '3092'">
                        <van-cell title="项目服务" :value="`${item.serviceName || ''} - ${item.serviceCode || ''}`" is-link @click="searchServiceCli(item,index)">
                            <template #right-icon>
                                <div class="iconBox">
                                    <!-- <van-icon name="close" size="18" class="iconRight" /> -->
                                    <!-- <van-icon name="arrow" size="18" class="iconRight" /> -->
                                </div>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>

                <!-- 选择项目服务 -->
                <van-popup v-model="select_sapServiceList_show" position="bottom" style="height: 90%">
                    <div class="popupDiv">
                        <div class="popupSearch">
                            <van-search v-model.trim="sapServiceVal" placeholder="搜索" @search="onSearchService" @clear="onSearchService" shape="round" background="#F4F4F4"></van-search>
                        </div>
                        <div class="popupCon conBorder">
                            <van-radio-group v-model="sapServiceChange" class="popupItem marginNone borderNone">
                                <van-radio v-for="uitem in sapServiceList" :key="uitem.id" :name="uitem" style="padding:10px">
                                    <span class="userNameClass_left">{{ uitem.serviceName }}</span>
                                    <span class="userNameClass_right">{{ uitem.serviceCode }}</span>
                                </van-radio>
                            </van-radio-group>
                        </div>
                        <div class="popupBtn">
                            <van-button style="width:100%;background: #1989fa;color: #ffffff;position: -webkit-sticky;position: sticky;bottom: 0;" round @click="searchExecutorBtn()">确定</van-button>
                        </div>
                    </div>
                </van-popup>

                <van-popup v-model="executor.show" position="bottom" v-if="canEdit" style="height: 90%">
                    <div class="popupDiv">
                        <div class="popupSearch" v-if="user.userNameNeedTranslate != '1'">
                            <van-search v-model="executor.searchText" placeholder="输入员工姓名搜索" @search="onSearch" shape="round" background="#F4F4F4"></van-search>
                        </div>
                        <div class="popupSearch" v-if="user.userNameNeedTranslate == '1'">
                            <van-search v-model="executor.searchText" placeholder="输入员工姓名搜索" @search="getOnSearch(executor.searchText)" shape="round" background="#F4F4F4"></van-search>
                        </div>

                        <div class="popupCon conBorder">
                            <van-radio-group v-model="executor.item" class="popupItem marginNone borderNone">
                                <van-radio v-for="uitem in executor.searchList" :key="uitem.id" :name="uitem" style="padding:10px">
                                    <span v-if="user.userNameNeedTranslate != 1" class="userNameClass_left">{{uitem.name}}</span>
                                    <span v-else class="userNameClass_left"><TranslationOpenDataText type='userName' :openid='uitem.name'></TranslationOpenDataText></span>
                                    <span class="userNameClass_right">{{ uitem.jobNumber }}</span>
                                </van-radio>
                            </van-radio-group>
                        </div>

                        <div class="popupBtn">
                            <van-button style="width:100%;background: #1989fa;color: #ffffff;position: -webkit-sticky;position: sticky;bottom: 0;" round @click="searchExecutor()">确定</van-button>
                        </div>
                    </div>
                </van-popup>
                
                <!-- 添加执行人 -->
                <div class="add_executor" @click="addExecutor" v-if="canEdit">添加执行人</div>
                <!-- 优先级 -->
                <van-field v-model="taskform.taskLevel" label="优先级" @click="taskLevel.show = true" readonly clickable>
                    <template #input><span>{{taskLevel.list[taskform.taskLevel]}}</span></template>
                </van-field>
                <van-popup v-model="taskLevel.show" position="bottom" v-if="canEdit">
                    <van-picker
                    show-toolbar
                    :columns="taskLevel.list"
                    @confirm="taskLevelChange"
                    @cancel="taskLevel.show = false;$forceUpdate();"/>
                </van-popup>

                <van-cell title="任务描述"/>
                <vue-html5-editor :content="taskform.taskDesc" :height="300" @change="htmleditor"></vue-html5-editor>

            </van-form>
            <div class="form_btn" style="position:fixed; bottom:0px;width:100%;z-index: 99">
                <div style="padding-bottom:10px;">
                    <van-button square block type="info" loading-text="保存中..." @click="submitTask" native-type="submit" :loading="submitTaskBtn" style="width:100%;float:left;" :disabled="!canEdit">
                        <div v-if="canEdit">保存</div>
                        <div v-else>暂无权限编辑</div>
                    </van-button>
                </div>
            </div>
                    
        </div>
    </div>
</template>

<script>
import { S } from 'core-js/modules/_export'

export default {
    data() {
        return {
            showOrNot: false,
            title: '编辑任务',
            user: JSON.parse(localStorage.userInfo),
            taskId: JSON.parse(sessionStorage.taskId),
            currentDate1: new Date(),
            currentDate2: new Date(),
            currentDate3: new Date(),
            minDate: new Date(2020,0,1),
            maxDate: new Date(2025,11,31),
            canEdit: true,
            onSearchTime: null,
            taskform:{      // 表单
                taskType: 0,
                name: '',
                startDate: null,
                endDate: null,
                finishDate: null,
                taskLevel: 0,
                executorList: [{executorName: '',executorId: '',planHours: 8}],

            },
            taskType:{
                show: false,
                list: ['任务','里程碑','风险']
            },
            startDateShow: false,
            endDateShow: false,
            finishDateShow: false,

            submitTaskBtn: false,

            taskLevel:{
                show: false,
                list: ['一般','重要','紧急']
            },
            executor:{
                show: false,
                item: {id:null,name:''},
                index: 0,
                list: [],
                searchList: [],
                searchText: ''
            },

            select_project_show: false,
            select_grouping_show: false,
            select_list_show: false,
            select_project: '请选择',
            select_grouping: '请选择',
            select_list: '请选择',
            select_project_array: [],
            select_project_array_tow: [], 
            select_grouping_array: [],
            select_list_array: [],
            select_project_show_searchText: '',

            integrationProjectList: [],

            sapServiceList:[], // 项目服务
            sapServiceListCoper:[], // 项目服务
            select_sapServiceList_show: false,
            sapServiceVal: '',
            sapServiceChange: {}
        }
    },
    mounted() {
        this.showOrNot = this.taskId.showOrNot
        this.getProjectList()
        if(!this.taskId.addNew){
            this.title = '编辑任务'
            this.getTask()
        }else{
            this.title = '新建任务'
            this.taskform = {
                projectId: '',
                groupId: '',
                stagesId: '',
                taskType: 0,
                name: '',
                startDate: null,
                endDate: null,
                finishDate: null,
                taskDesc: '',
                taskLevel: 0,
                executorList: [{executorName: '',executorId: '',planHours: this.user.timeType.allday}]
            }
            if(!this.taskId.showOrNot) {
                this.taskform.groupId = this.taskId.groupId
                this.taskform.stagesId = this.taskId.stagesId
                this.taskform.projectId = JSON.parse(sessionStorage.projectId)
            }
        }
        this.getUsersList()

        const { companyId } = this.user
        if(companyId == 3092) {
            this.getSapServiceList()
        }
        console.log('mounted',this.taskId,null);
    },
    methods: {
        selectProject(value,key) {
            this.select_project = this.taskform.projectId.projectName
            this.taskform.projectId = this.taskform.projectId.id
            console.log(this.taskform)
            this.getTaskGrouping()
            this.select_project_show_searchText = ''
            this.select_project_show = false

            this.select_list = ''
            this.select_grouping = ''
            this.taskform.groupId = ''
            this.taskform.stagesId = ''
        },
        selectGrouping(value){
            this.select_grouping = value.name
            this.taskform.groupId = value.id
            this.getStageList()
            this.select_grouping_show = false

            this.select_list = ''
            this.taskform.stagesId = ''
        },
        selectList(value) {
            console.log(value)
            this.select_list = value.stagesName
            this.taskform.stagesId = value.id
            this.select_list_show = false
        },
        onSearchProject() {
            let text = this.select_project_show_searchText
            if(text != '') {
                let projectArr = this.select_project_array_tow
                var arr = []
                for(var i in projectArr) {
                    if(projectArr[i].projectName.indexOf(text) != '-1') {
                        arr.push(projectArr[i])
                    }
                }
                this.select_project_array = arr
            } else {
                this.select_project_array = this.select_project_array_tow
            }
        },
        getProjectList() {
            this.$axios.post("/project/getProjectList", {})
            .then(res => {
                if(res.code == "ok") {
                    this.select_project_array = res.data
                    this.select_project_array_tow = res.data
                    this.getRecentlyProject()
                } else {
                    this.$toast.fail('失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getRecentlyProject() {
            this.$axios.post('/project/nearProject',{})
            .then(res => {
                if(res.code == 'ok'){
                    this.integrationProjectList = res.data
                }
            }).catch(err => {this.$toast.clear();this.cardRefLoading = false;})
        },
        getTaskGrouping() {
            this.$axios.post("/task-group/list", {projectId:this.taskform.projectId})
            .then(res => {
                if(res.code == "ok") {
                    this.select_grouping_array = res.data
                } else {
                    this.$toast.fail('失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getStageList() {
            this.$axios.post("/stages/list", {
                projectId: this.taskform.projectId,
                groupId: this.taskform.groupId,
                order: 'seq',
                isDesc: false
            })
            .then(res => {
                if(res.code == "ok") {
                    this.select_list_array = res.data.list
                } else {
                    this.$toast.fail('失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },

        back() {
            history.back();
        },
        formatDate(date) {
            let mon = date.getMonth() + 1
            return `${date.getFullYear()}-${mon<10?'0'+mon:mon}-${date.getDate()<10?'0'+date.getDate():date.getDate()}`;
        },


        tasktypeChange(value,key){      // 类型
            this.taskform.taskType = key
            this.taskType.show = false
        },
        startDateChange(date){          // 开始时间
            this.taskform.startDate = this.formatDate(date)
            this.startDateShow = false
        },
        endDateChange(date){            // 截止时间
            this.taskform.endDate = this.formatDate(date)
            this.endDateShow = false
        },
        finishDateChange(date){         // 完成时间
            this.taskform.finishDate = this.formatDate(date)
            this.finishDateShow = false
        },
        
        executorChange(item,index){  // 选择执行人
            this.executor.searchText = ''
            this.getOnSearch('')
            this.executor.show = true
            this.executor.index = index
            this.executor.searchList.forEach(u=>{if (u.id == item.executorId) {
                this.executor.item = u
            }})
        },
        deleteExecutor(index){
            console.log('deleteExecutor');
            this.taskform.executorList.splice(index,1)
            this.$forceUpdate();
        },
        addExecutor(){
            console.log('addExecutor');
            this.taskform.executorList.push({
                executorName: '',
                executorId: '',
                planHours: this.user.timeType.allday
            })
            this.$forceUpdate();
        },
        onSearch(val) {
            if(this.user.userNameNeedTranslate != 1) {
                this.executor.searchList = [];
                this.executor.list.forEach(u=>{if (u.name.startsWith(val)) {
                    this.executor.searchList.push(u);
                }})
            } else {
                if (this.onSearchTime != null) {
                    clearTimeout(this.onSearchTime)
                }
                var that = this
                this.onSearchTime = setTimeout(() => {
                    that.getOnSearch(val)
                }, 500)
            }
        },
        getOnSearch(val) {
            this.$axios.post("/user/getEmployeeList", {
                departmentId: -1,
                pageIndex: 1,
                pageSize: 200,
                keyword: val,
                status: 1,
                roleId: '',
                cursor: '',
                onlyDirect: 0,
            })
            .then(res => {
                if(res.code == "ok") {
                    this.executor.searchList = res.data.records
                } else {
                    this.$toast.fail('搜索失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        searchExecutor(){
            this.taskform.executorList[this.executor.index].executorId = this.executor.item.id
            this.taskform.executorList[this.executor.index].executorName = this.executor.item.name
            this.executor.show = false
            console.log('searchExecutor',this.executor.item,this.executor.item.name);
        },

        taskLevelChange(value,key){     // 优先级
            this.taskform.taskLevel = key
            this.taskLevel.show = false
        },

        executorProjectJudgment(arr) {
            let arrList = JSON.parse(JSON.stringify(arr))
            // 将填写的数据以执行人id相同处理成二维数据
            const result = Object.values(arrList.reduce((acc, obj) => {
            const { executorId } = obj;
                if (!acc[executorId]) {
                    acc[executorId] = [];
                }
                acc[executorId].push(obj);
                return acc;
            }, {}));
            console.log(result, '<== 去重后的数据')
            // 更具二维数据去判断项目服务是否相同
            for(var i in result) {
                const item = result[i]
                if(item.length > 1) {
                    let size=new Set(item.map(item=>item.serviceId)).size
                   if(size != item.length) {
                        return false
                   } 
                }
            }

            return true
        },

        submitTask(){
            console.log('submitTask');
            const { companyId } = this.user
            if(!this.taskform.name.replace(/^\s*|\s*$/g,"")){
                return
            }
            
            // 执行人查重
            if(companyId != '3092') {
                let arr = this.taskform.executorList
                let json={};
                for(let i in arr){
                    if(!json[arr[i].executorId]){
                        json[arr[i].executorId]=1;
                    }else{
                        this.$toast.fail("执行人存在重复");
                        return
                    }
                }
            }

            // 针对依斯倍排除执行人相同和项目服务相同的任务
            if(companyId == '3092') {
                if(!this.executorProjectJudgment(this.taskform.executorList)) {
                    this.$toast.fail("同一个项目服务执行人相同");
                    return
                }
            }

            if(this.taskform.projectId == '' || this.taskform.projectId == null) {
                this.$toast.fail("请选择所属项目");
                return
            }
            if(this.taskform.groupId == '' || this.taskform.groupId == null) {
                this.$toast.fail("请选择所属任务分组");
                return
            }
            if(this.taskform.stagesId == '' || this.taskform.stagesId == null) {
                this.$toast.fail("请选择所属任务列表");
                return
            }

            // 去除未选择执行人的执行人列表
            this.taskform.executorList = this.taskform.executorList.filter(item => item.executorId)
            this.taskform.executorListStr = JSON.stringify(this.taskform.executorList)
            
            delete this.taskform.executorList
            delete this.taskform.subTaskList;
            delete this.taskform.refTaskList;
            delete this.taskform.progress;

            this.submitTaskBtn = true
            // return
            this.$axios.post("/task/save", this.taskform)
            .then(res => {
                this.submitTaskBtn = false
                if(res.code == "ok") {
                    this.$toast.success('保存成功');
                    this.back()
                } else {
                    this.$toast.fail('保存失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err);this.submitTaskBtn = false});

        },
        htmleditor(e) {
            this.taskform.taskDesc = e
            console.log()
        },
        getTask(){
            this.$axios.post("/task/getTask", {
                id: this.taskId.id
            }).then(res => {
                if(res.code == "ok") {
                    this.taskform = res.data
                    this.taskform.createDate = null;
                    this.taskform.indate = null;
                    let projectManagement = false
                    let authorityEditing = false
                    if(!this.taskform.taskDesc){
                        this.taskform.taskDesc = ''
                    }
                    if(this.taskform.startDate){
                        this.currentDate1 = new Date(this.taskform.startDate)
                    }
                    if(this.taskform.endDate){
                        this.currentDate2 = new Date(this.taskform.endDate)
                    }
                    if(this.taskform.finishDate){
                        this.currentDate3 = new Date(this.taskform.finishDate)
                    }
                    // 判断编辑权限
                    for(let i in this.user.functionList){
                        if(this.user.functionList[i].name == '查看全部项目'){
                            projectManagement = true
                        }
                        if(this.user.functionList[i].name == '编辑项目内任务') {
                            authorityEditing = true
                        }
                    }
                    if(authorityEditing || this.user.id == res.data.createrId || this.user.id == res.data.projectInchargerId || this.user.id == res.data.groupInchargerId || projectManagement || this.title == '新建任务'){
                        this.canEdit = true
                    }else{
                        this.canEdit = false
                    }


                    // (
                    //     (
                    //         (addForm.executorListFront == null || addForm.executorListFront.length<10) 
                    //         && (
                    //             addForm.id == null|| user.id == addForm.createrId || currentProject.inchargerId == user.id || permissions.projectManagement
                    //         )
                    //     ) 
                    //     || groupResponsibleId == user.id
                    // )



                    // this.canEdit = false
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getUsersList(){
            this.$axios.post("/user/getSimpleActiveUserList", {})
            .then(res => {
                if(res.code == "ok") {
                    this.executor.list = res.data
                    this.executor.searchList = res.data
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getSapServiceList() {
            this.$axios.post("/sap-project-service/sapServiceList", {})
            .then(res => {
                if(res.code == "ok") {
                    this.sapServiceList = res.data
                    this.sapServiceListCoper = JSON.parse(JSON.stringify(res.data))
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        onSearchService() {
            if(!this.sapServiceVal) {
                this.sapServiceList = JSON.parse(JSON.stringify(this.sapServiceListCoper))
                return
            }
            let arr = JSON.parse(JSON.stringify(this.sapServiceListCoper.filter(item => item.serviceName.indexOf(this.sapServiceVal) != -1 || item.serviceCode.indexOf(this.sapServiceVal) != -1)))
            this.sapServiceList = arr
        },
        searchExecutorBtn() {
            console.log(this.sapServiceChange)
            const { serviceName, serviceCode, id } = this.sapServiceChange
            this.taskform.executorList[this.executor.index].serviceId = id
            this.taskform.executorList[this.executor.index].serviceName = `${serviceName}`
            this.taskform.executorList[this.executor.index].serviceCode = `${serviceCode}`
            this.select_sapServiceList_show = false
            console.log(this.taskform.executorList)
        },
        searchServiceCli(item,index) {
            const { companyId } = this.user
            this.executor.index = index
            this.sapServiceListCoper.forEach(u=>{if (u.id == item.serviceId) {
                console.log(u)
                this.sapServiceChange = {
                    serviceName: u.serviceName,
                    serviceCode: u.serviceCode,
                    id: u.id,
                    companyId
                }
            }})
            console.log(this.sapServiceChange, item)
            this.select_sapServiceList_show = true
        }
    },
}
</script>

<style lang="less" scoped>
.content{
    margin-top: 46px;
    overflow: auto;
    .add_executor{
        font-size:13px;
        color:#1989fa;
        padding-left:0.42667rem;
        padding-bottom:.2rem;
        width:100px
    }
    .delete_executor{
        position: absolute;
        top: 3px;
        right: 3px;
        font-size: 22px;
        color: #c03131;
    }
}
.xinmingghao {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    display: inline-block;
    float: right;
}

.iconBox {
    position: relative;
    top: 2px;
}
.iconRight {
    margin-left: 10px;
}
.ewProjectlist {
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid #666;
    color: #a5a5a5;
}
</style>
<style>
    .editaskBox .van-radio__label {
        width: 100%;
        display: inline-block;
    }
</style>